.sidebar {
    width: 210px;
    height: 100vh;
    /* padding: 10px; */
    background-color: rgba(242, 242, 242, 1);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .sidebarDiv {
    width: 100%;
    height: 100%;
    /* border-radius: 20px; */
    /* background: rgba(255, 255, 255, 1); */
    background: #2d64bc;

    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
  }
  
  .sidebarLogoDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 10px;
  }
  
  .sideBarLogoImage {
    max-width: 100%;
    height: auto;
    background: #ffff;
  }
  
  .sidebarListDiv {
    width: 100%;
    margin-top: 15%;
  }
  
  .sidebarListDiv ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
  }
  
  .sidebarListDiv li {
    /* background-color: rgba(255, 255, 255, 1); */
    margin: 4% 0;
    font-weight: 500;
    padding: 5% 15%;
    color: #ffff;
    border-radius: 10px;
    font-size: .9rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
  }
  
  /* a.sideBarLink {
    text-decoration: none;
    color: rgba(121, 121, 121, 1);
  } */
  
  .sidebar-link {
    text-decoration: none;
    color: rgba(121, 121, 121, 1);
    width: 100%; /* Make the link fill the list item */
    display: block; /* Make the link fill the list item area */
  }
  
  /* 
  a.sideBarLink {
    text-decoration: none;
    color: rgba(121, 121, 121, 1);
  } */
  
  .sidebarListDiv li.active {
    /* background: linear-gradient(90deg, #33BB78 0%, #73ADE3 100%); */
    background: #ffff;
    color: #2d64bc; 
    font-weight: 600;
  }
  
  .sidebarListDiv li:hover {
    font-weight: 600;
    background: #ffffff;
    /* background: linear-gradient(90deg, #33BB78 0%, #73ADE3 100%); */
    color: #2d64bc;
    transform: scale(1.05);
  }
  
  .sidebarListDiv li a {
    text-decoration: none;
    color: inherit; /* Inherit color from parent li */
    width: 100%; /* Make the link fill the list item */
    display: block; /* Make the link fill the list item area */
  }
  
  .sidebar {
    scrollbar-width: thin; /* Firefox scrollbar style */
  }
  
  .sidebar::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }
  
  .sidebar::-webkit-scrollbar-track {
    background: rgba(242, 242, 242, 1); /* Track color */
  }
  
  .sidebar::-webkit-scrollbar-thumb {
    background-color: #33BB78; /* Scrollbar thumb color */
    border-radius: 10px; /* Rounded edges */
  }
  
  @media (max-width: 900px) {
    .sidebarListDiv li {
      /* font-size: 0.9rem;  */
      padding: 5% 4%;
    }
  }










.menu-item:hover {
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.menu-item.active {
  background: rgba(255, 255, 255, 0.2);
  border-left: 5px solid #959daa; /* Highlight with a golden border */
}

.menu-item i {
  font-size: 16px;
}

a.submenutag {
  font-size: 13px;
}

.submenu {
  list-style: none;
  margin-top: 5px;
}

.submenu-item {
  padding: 5px 20px;
  font-size: 14px;
  color: #d1d1e0;
  transition: background 0.3s, color 0.3s;
}

.submenu-item.active {
  color: #ffd700;
  font-weight: bold;
}

.submenu-item:hover {
  color: white;
  /* text-decoration: underline; */
}

i.fas.fa-solid.fa-building.me-3 {
  margin-left: 4px;
}

.bg-gradient {
  background: linear-gradient(135deg, #3b5998, #192f6a);
}

.expandReport {
  font-size: 13px !important;
  margin-bottom: 1px;
}

/* Responsiveness */
@media (max-width: 900px) {
  .sidebar {
    width: 200px;
  }

  .menu-item {
    font-size: 14px;
  }

  .submenu-item {
    font-size: 12px;
  }
}

  



/* Sidebar container */
.sidebar {
  width: 210px;
  height: 100vh;
  background: #2d64bc;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease-in-out;
}

/* Sidebar closed for tablet and smaller screens */
.sidebar.closed {
  transform: translateX(-100%);
}

/* Sidebar toggle button */
.sidebar-toggle-btn {
  display: none;
  position: absolute;
  top: 15px;
  left: 15px;
  background: #2d64bc;
  color: white;
  border: none;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  z-index: 1001;
}

/* Show toggle button on tablets and below */
@media (max-width: 1024px) {
  .sidebar-toggle-btn {
    display: block;
    margin-left: -5px;
    padding: 4px 10px !important;
    margin-top: -3px;
    border-radius: 7px;
  }

  .sidebar {
    width: 250px;
    height: 100vh;
    position: fixed;
    z-index: 1000;
  }

  .sidebar-toggle-btn {
    /* position: fixed; */
    /* top: 15px;
    left: 15px; */
    border: none;
    /* padding: 10px 15px; */
    cursor: pointer;
    /* font-size: 20px; */
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .close-sidebar-btn {
    position: absolute;
    top: 15px;
    right: -20px; /* Half outside the sidebar */
    width: 28px;
    height: 28px;
    background-color: #fff;
    color: #333;
    border: none;
    border-radius: 50%;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
  }
  
  .sidebar.open .close-sidebar-btn {
    right: -15px; /* Keeps half outside the sidebar */
  }
  
}
