.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Darker overlay for better contrast */
    backdrop-filter: blur(15px); /* More pronounced blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it covers other content */
    opacity: 1; /* Default fully visible */
    visibility: visible; /* To support smooth hiding */
    transition: opacity 0.5s ease, visibility 0.5s ease; /* Smooth fade-out */
}

.loader-overlay.hidden {
    opacity: 0; /* Invisible */
    visibility: hidden; /* Prevents interaction */
}

.loader {
    text-align: center; /* Center the loader and text */
    animation: fadeIn 0.5s; /* Optional fade-in effect for the loader */
}

.loader img {
    width: 100px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    animation: blink 1.5s infinite; /* Blinking effect */
}

.loading-text {
    margin-top: 15px; /* Space between image and text */
    color: #ffffff; /* White text for contrast */
    font-size: 20px; /* Adjust as needed */
    animation: fadeIn 1s; /* Fade-in effect for loading text */
}

/* Blinking animation */
@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

/* Optional fade-in effect */
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
