
.otp-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
  }
  
  h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .otp-inputs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;
  }
  
  .otp-field {
    width: 45px;
    height: 45px;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    border: none;
    border-radius: 3px;
    outline: none;
    background: rgba(216, 216, 216, 0.3);
}
  
  .otp-field:focus {
    border-color: #00c9a7 !important;
  }
  
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  

  .verify-button {
      border: 0;
      padding: 7px 10px !important;
      color: #fff;
      width: 100% !important;
      font-weight: 600;
      transition: box-shadow 0.3s ease, transform 0.3s ease;
      border-radius: 4px;
      background: #2d64bc !important;
  }

  .verify-btn-div {
    margin-top: 2%;
    margin-bottom: 11px;
}

h1.resent-otp-countdown-text {
  font-size: 14px;
  font-weight: 500;
}

span.resent-otp-countdown {
  color: #2d64bc;
  font-weight: 600;
}

.resent-div1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resent-div {
  display: grid;
  margin-top: 1rem;
}

h1.resent-otp-countdown-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(103, 105, 104, 1);
}

button.btn.resend-btn {
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  color: #2d64bc;
  text-decoration: underline;
}

.verify-password-form {
    display: grid;
    justify-content: center;
    align-items: center;
}

.otp-error {
  /* margin-top: 10px; */
}
