/* src/components/DashboardLayout.css */
.dashboard-layout {
    display: flex;
  }
  
  .main-content.dashboardMain {
    margin-left: 210px; /* Sidebar width */
    flex-grow: 1; /* Allow the main content to fill remaining space */
    background: rgba(242, 242, 242, 1);
  }
  
  .content {
    padding: 3% 3% 3% 3%;
    /* margin-top: 60px;  */
    min-height: calc(100vh - 60px); 
  }
  
  .contentHeader {
    display: flex;
    gap: 15px;
    padding-bottom: 10px;
  }
  

  .contentDiv {
    margin-right: 20%;
}

  .buttonValue {
    background: #2d64bc;
    /* background: linear-gradient(90.61deg, #33BB78 0%, #73ADE3 103.46%); */
    border: none !important;
    border-radius: 8px !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    width: 40%;
  }

  img.addIcon {
    width: 12px;
    margin-bottom: 1%;
  }


  /* src/components/DashboardLayout.css */

.selectDiv {
  width: 50% !important;
  /* border: 1px solid #ccc !important; */
  /* border-radius: 8px !important;  */
  background-color: #fff !important; /* White background for the dropdown */
  transition: border-color 0.3s, box-shadow 0.3s !important; /* Smooth transition */
  appearance: none !important; /* Remove default dropdown arrow */
  position: relative !important; /* For the custom arrow */
  font-size: 12px !important; 
  border : none !important;
}

.selectDiv:focus {
  /* border-color: #33BB78 !important; */
  box-shadow: 0 0 5px rgba(51, 187, 120, 0.5) !important; 
}

.selectDiv option {
  background-color: #fff; /* White background for options */
  color: #333; /* Dark text color */
  font-size: 12px; /* Reduced font size for options */
  padding: 5px 10px; /* Optional padding for options */
}

/* Option hover state */
.selectDiv option:hover {
  background-color: #f0f0f0; /* Light gray on hover */
  color: #000; /* Darker text color */
}

/* Add a custom arrow */
.selectDiv::after {
  content: '';
  position: absolute !important;
  right: 15px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  border: solid transparent !important;
  border-width: 5px 5px 0 !important;
  border-top-color: #ccc !important; /* Color of the dropdown arrow */
  pointer-events: none !important; /* Prevent clicking on the arrow */
}

/* Hover state for dropdown */
.selectDiv:hover {
  border-color: #33BB78 !important; /* Change border color on hover */
}

/* Style the options dropdown */
.selectDiv option {
  background-color: #fff !important; /* White background for options */
  color: #333 !important; /* Dark text color */
}

/* Option hover state */
.selectDiv option:hover {
  background-color: #f0f0f0 !important; /* Light gray on hover */
  color: #000 !important; /* Darker text color */
}

/* Sidebar Styles */
.filter-sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 300px;
  padding: 20px;
  background: #f8f9fa;
  border-right: 1px solid #dee2e6;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  border-radius: 5px;
}

.filter-sidebar.collapsed {
  width: 60px; /* Collapsed state width */
  padding: 10px;
  overflow: hidden;
}

.filter-sidebar.open {
  width: 250px;
  padding: 20px;
}

.filter-topbar-icons{
  color: #b3abab;
}

/* Toggle Button */
.toggle-button {
  position: absolute;
  top: 10px;
  z-index: 10;
  right: 17px;
  background-color: #cfcece;
  color: #fff;
  font-weight: bold;
  width: 0px;
  transition: transform 0.3s ease;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.filter-sidebar.open .toggle-button {
  position: absolute;
  top: 10px;
  z-index: 10;
  right: 26px;
  background-color: #cfcece;
  color: #fff;
  width: 0px;
  font-size: 10px;
  font-weight: bold;
  transition: transform 0.3s ease;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
.filterIcon{
  font-size: 12px;
}

.toggle-button span {
  font-size: 18px;
}

.statusNotFound {
  font-size: 13px;
  text-align: center;
  font-weight: 600;
  color: #909090 !important;
}

.toggle-button:hover, .filter-sidebar.open .toggle-button:hover {
  background-color: #5087d6;
}


/* Main Content Styling */
.content-area {
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
  width: 0px;
  padding: 20px;
}

/* Card Styling */




.filter-sidebar .filter-content {
  padding: 10px 0px 10px 0px;
}


.content-area {
  flex-grow: 1;
  padding: 20px;
}

/* styles.css */
.filter-title {
  font-size: 15px;            /* Increase the font size */
  font-weight: bold;           /* Make the text bold */
  color: gray;             /* Change the text color */
  text-align: center;         /* Center the title */
  /* letter-spacing: 1px; */
  margin-top: 5px;
}


.dateRangeModal {
  position: relative;
  display: flex
;
  flex-direction: column;
  width: auto;
  right: 200px;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}


/* CSS for the background blur */
.blurred-background {
  filter: blur(5px);
  transition: filter 0.3s ease;
}

/* Optionally, you can add other styles like dimming the background */
.blurred-background-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.dateRangespan {
  font-size: 12px !important;
  font-weight: 600;
}

.selectDateRange {
  background: #5d8ff7;
  color: #fff;
  padding: 4px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: space-around;
}

.selectDateRange:hover, .selectDateRange:focus {
  background: #1054c1;
  color: #fff;
}

h6.countDetails {
  font-weight: 700;
  font-size: 18px;
  color: #2d64bc;
  margin-bottom: 0px;
}

.breakTitle {
  color: #808080b3 !important;
  font-weight: 600;
  font-size: 14px;
}

.breakMainTitle {
  font-size: 16px;
  font-weight: 600;
  color: #2d64bc;
  margin-bottom: 10px;
}
.top-section {
  box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.15);
  padding: 8px 15px 8px 15px;
}

.filterLabel {
  font-weight: 550;
  font-size: 13px;
  text-align: center;
  color: #909090;
}

.mt-2.radioDiv {
  display: flex;
  justify-content: start;
  align-items: center;
}

.filter-form-check {
  display: flex;
  min-height: 0.5rem;
  padding-left: 1.5rem;
  margin-bottom: 0.125rem;
  font-size: 12px;
}

.radioLabel {
  margin-left: 5px;
}

.topBarBox {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.topBarMain {
  padding: 10px 0px 5px 20px;
}

.iconHeader {
  font-size: 10px;
}

/* Customize the scrollbar for the Break and Violation sections */
.custom-scrollbar {
  overflow-x: auto;
  scrollbar-width: none;
}

/* Webkit-based browsers (Chrome, Safari, Edge) */
.custom-scrollbar::-webkit-scrollbar {
  width: 2px; /* Set the width of the scrollbar */
}

.filter-custom-scrollbar {
  overflow-x: auto;
  scrollbar-width: thin;
}

.filter-custom-scrollbar::-webkit-scrollbar {
  width: 1px; /* Set the width of the scrollbar */
}

span.dateIcon {
  margin-left: 10px;
  color: rgb(61, 145, 255);
}


.payment_status_switch {
  /* padding: 0px 0px 0px 20px; */
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.status_switch{
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.user_roles_switch{
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.userListSearch {
  font-size: 12px !important;
}

.form-switch-payment {
  padding-right: 10px;
}

.payment-label {
  font-size: 12px;
}

div#statusSelect {
  width: 160px;
}

.selectedStatusList {
  display: block;
  /* width: 200px; */
}

div#usersSelect {
  width: 160px;
}



/* Style for the unchecked state */
input[type="checkbox"]:not(:checked) {
  background-color: #fff; /* White background */
  border-color: #ccc; /* Light gray border */
}

/* Style for the checked state */
input[type="checkbox"]:checked {
  background-color: #007bff; /* Blue background */
  border-color: #007bff; /* Blue border */
}

/* Change color of the li element when checkbox is checked */
.checked-li {
  background-color: #e0f7fa; /* Light cyan background when checked */
}

/* Add smooth transition for background color changes */
input[type="checkbox"], li {
  transition: background-color 0.3s, border-color 0.3s;
}


.userSelectAllLabel {
  font-size: 12px;
  color: rgb(149, 140, 140);
  cursor: pointer;
  font-weight: 600;
}

.checkbox-value {
  font-size: 12px;
  color: rgb(149, 140, 140);
  cursor: pointer;
  font-weight: 600;
}

.filterIconDiv {
  padding-right: 10px;
  padding-left: 10px;
}

.selectAlluserMainDiv{
  margin-left: 16px;
}

.filterSearch {
  font-size: 12px !important;
  padding-left: 3px;
  /* border: none !important; */
  border: 1px solid rgb(222 222 222);
  border-radius: 5px;
  transition: border-color 0.3s ease-in-out; 
  outline: none; 
  width: 93%;
  font-weight: 600;
  color: #909090 !important;
}


.filterSearch::placeholder {
  color: #909090 !important;
  opacity: 1; /* Ensures the placeholder is fully visible */
}

/* Optional: Change color when input is focused */
.custom-input:focus::placeholder {
  color: lightgray;
}