.profileCardMainDiv {
  padding: 30px 100px 30px 100px;
}
.inputFiledProfile {
    margin-bottom: 17px;
  }

@media (max-width: 500px) {
    .profileCardMainDiv {
        padding: 40px 30px 40px 30px;
      }
}
