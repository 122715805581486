/* General layout styling */

.container.row {
  align-items: center;
}

.container-fluid {
  padding: 0;
}

header.header-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-section {
  background-color: #ffff;
  padding: 0.5rem 0;
  position: fixed;
  height: 65px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.header-section h1 {
  color: white;
  font-size: 2rem;
  font-weight: 600;
}

span.contactUsDesc {
  font-size: 1rem;
  font-weight: 500;
  color: #0e2241;
  margin-left: 50px;
}

.contactNo {
  text-decoration: underline; /* You can also use overline or line-through */
}

/* Main content section with left and right sides */
.main-content.registrationMainDiv {
  display: flex;
  height: 100vh;
  padding-top: 80px; /* Make space for fixed header */
}

/* Right Side (Form) */
.right-form {
  width: 50%;
  overflow-y: scroll; /* Enable scrolling */
  padding: 2rem;
  background-color: #ffffff;
  z-index: 10;
  scrollbar-width: none; /* For Firefox */
  margin-left: 50%;
  scroll-behavior: smooth;
  /* height: 100vh; */
}

.form-container {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 0px !important;
}

.registerBtn {
  background-color: #2d64bc;
  color: white;
  font-weight: 600;
  padding: 10px;
  border: none;
  font-size: 1rem;
}

.registerBtn:hover {
  background-color: #1a47a4;
}

.carousel-caption {
  margin-bottom: -110px !important;
}
.carousel-indicators {
  margin-bottom: -1rem !important;
}
/* Carousel Indicators */
.carousel-indicators [data-bs-target] {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: linear-gradient(135deg, #6a11cb, #2575fc); /* Gradient color */
  border: 1px solid #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.carousel-indicators [data-bs-target]:hover {
  transform: scale(1.2);
}

.carousel-indicators [data-bs-target].active {
  background: #2d64bc;
}

/* Carousel Item */
.carousel-item:first-child .sliderImageDiv {
  margin-left: 2rem;
  margin-bottom: 4rem !important;
  margin-top: -30px !important;
}

.carousel-inner {
  padding-bottom: 2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .leftSideSliderDiv {
    display: none; /* Hide the carousel on mobile */
  }
  .registeration-main {
    padding: 20px;
  }
  .registrationCard {
    width: 100%;
  }
}

/* Ensure responsiveness */
@media (max-width: 768px) {
  .left-slider,
  .right-form {
    width: 100%;
  }
}

/* Registration Form */
.registeration-main {
  background-color: rgba(78, 122, 191, 0.3) !important;
}
.registrationCard {
  border-radius: 1rem;
  border: none !important;
}
.leftSideDiv {
  padding-top: 4% !important;
}
.RegistrationTitle {
  font-family: "Nunito", sans-serif !important;
  font-size: 1.7rem;
  font-weight: 600;
  color: #000;
  line-height: 49px;
  padding-top: 0.2rem;
}
.registerBtn {
  border: 0;
  padding: 7px 10px !important;
  color: #fff;
  width: 100% !important;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  border-radius: 4px;
  background: #2d64bc !important;
}
.registerBtn:hover {
  color: white !important;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15), 0 8px 10px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}
.registerForm {
  padding: 0 2rem 2rem;
}
.registerLabel {
  color: rgba(103, 105, 104, 1);
  font-size: 14px;
  font-weight: 500;
}
.register-input {
  border: none;
  border-bottom: 1.5px solid #ccc;
  padding: 9px;
  border-radius: 10px;
  font-weight: 500;
  width: 100%;
  font-size: 12px;
  background: rgba(227, 227, 227, 0.3);
  outline: none;
  transition: border-color 0.3s ease-in-out;
}
.register-input:focus {
  border-bottom-color: #2d64bc !important;
  box-shadow: 0 1px 0 0 rgba(0, 201, 167, 0.2);
}
.register-error {
  font-size: 12px;
  font-weight: 600;
  margin-top: -1.3rem;
}
.registerFormTitle {
  color: #2d64bc !important;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 38.19px;
  text-align: center;
  padding-top: 2rem;
  margin-top: 5.3rem !important;
}

/* Carousel Images */
.sliderImageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sliderImageDiv img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}
.slideImage {
  width: 50% !important;
}
.sliderImgDesc {
  text-align: center;
  font-weight: 600;
  margin-top: 4px !important;
  color: #0e2241;
  margin-bottom: 0.3rem;
}

.sliderImgDesc1 {
  text-align: center;
  font-weight: 600;
  margin-top: 4px !important;
  color: #0e2241;
  margin-top: -1rem;
  margin-bottom: 3.5rem;
}

.registerFormDesc {
  font-weight: 600;
}

/* Floating Label Form */
.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}
.form-input {
  width: 100%;
  border: none;
  padding: 0.6rem 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 600;
  outline: none;
  background: none;
  color: #444444;
  transition: border-color 0.3s, box-shadow 0.3s;
}
.form-input:focus {
  /* border-color: #4a90e2; */
  border-color: #2d64bc;
  /* box-shadow: 0 0 2px 4px rgba(74, 144, 226, 0.2); */
}
.form-label {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: 600;
  color: #6c757d;
  background-color: #fff;
  padding: 0 0.25rem;
  /* pointer-events: none; */
  transition: all 0.3s ease;
}
.form-input:focus + .form-label,
.form-input:not(:placeholder-shown) + .form-label {
  top: -0.1rem;
  font-size: 14px;
  color: #4a90e2;
}

/* Left and Right Sections */
.left-slider {
  /* background: #2d64bc4d; */
  background: #f2f6fe;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 1;
  overflow: hidden;
}

.form-container {
  max-width: 500px;
  margin: 0 auto;
}

img.logoImage {
  max-height: 40px;
  margin-right: 6px;
  width: 231px;
  height: 35px;
}

.CarouselClass {
  margin-top: -30px;
}

.form-title {
  font-size: 1.6rem;
  margin-bottom: 2rem !important;
  /* font-size: 32px; */
  font-weight: 600;
  color: #0e2241;
  line-height: 49px;
}

.slideImage {
  object-fit: cover;
}

.right-form::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.LoginAccount {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}

a.SignInLink {
  text-decoration: none;
  font-weight: 550;
}

.passwordDiv {
  position: relative !important;
}

img.show-password-icon {
  width: 15px;
}

span.change-toggle-password {
  position: absolute;
  top: 8px;
  right: 5px;
}

label.form-label.addressFormLabel {
  margin-top: -20px !important;
}

.form-input:focus + .form-label.addressFormLabel,
.form-input:not(:placeholder-shown) + .form-label.addressFormLabel {
  top: 1rem;
  font-size: 14px;
  color: #4a90e2;
}

.col-md-4.countryCodeDiv {
  margin-left: -20px !important;
  width: 37.6%;
}

.dropdown-menu.codeSelect {
  width: auto !important;
  font-size: 12px;
}

/* .dropdown-item.active, .dropdown-item:active{
  background: #2d64bc !important;
} */

input#companyPhoneNo {
  width: 105%;
}

.companyPhoneNoDiv {
  margin-left: -20px;
}

input#termsAndConditions {
  width: 12px;
}

.termAndCondition {
  font-size: 13px !important;
}

.termAndConditionSecond {
  margin-top: 20px !important;
  margin-left: 8px;
}

.termsAndConditionsError {
  margin-top: 1.5rem !important;
}

.passwordValidation {
  left: 0.75rem;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: 600;
  color: #6c757d;
  background-color: #fff;
  padding: 0 0.25rem;
  /* pointer-events: none; */
  transition: all 0.3s ease;
}

.passwordValidationDiv {
  margin-top: 0px !important;
  margin-bottom: 10px;
  margin-left: 10px;
}

.countryCodeDivOption {
  width: auto !important;
  padding: 8px !important;
  cursor: pointer;
  font-size: 13px;
}

.reCaptchaDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

input#phone {
  width: 105%;
}

.register-container {
  padding-top: 80px;
}

.recaptchaWrapper {
  transform: scale(0.8); /* Adjust scale as needed */
  transform-origin: 0 0; /* Ensure it scales properly */
  width: fit-content;
}

.termAndConditionSecondRegister {
  margin-top: 21px !important;
  margin-left: 9px;
}

@media (min-width: 320px) and (max-width: 375px) {
  .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height for vertical centering */
    width: 100%;
    flex-direction: column;
  }

  .right-form {
    width: 100% !important;
    max-width: 400px;
    margin: 0 auto !important;
    padding: 0.5rem;
  }
  img.logoImage {
    max-height: 40px;
    margin-right: 6px;
    width: 160px;
    height: 25px;
  }
  .left-slider {
    display: none;
  }
  .contactUSText {
    font-size: 12px;
    margin-left: 25px;
  }
  .termAndConditionSecondRegister {
    margin-top: 9px !important;
    margin-left: 85px;
    font-size: 10px;
    width: 100px;
  }
  .termAndCondition {
    font-size: 10px !important;
  }

  iframe {
    max-width: 100% !important;
    height: auto !important;
    transform: scale(0.7);
    transform-origin: top left;
    margin-left: 19%;
    margin-bottom: -10px;
  }
  input#phone,
  input#companyPhoneNo {
    width: 105%;
    margin-left: 10px;
  }
  .reCaptchaDiv {
    margin-bottom: 0px;
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height for vertical centering */
    width: 100%;
    flex-direction: column;
  }

  .right-form {
    width: 100% !important;
    max-width: 500px;
    margin: 0 auto !important;
    padding: 0.5rem;
  }
  img.logoImage {
    max-height: 40px;
    margin-right: 6px;
    width: 200px;
    height: 30px;
  }
  .left-slider {
    display: none;
  }
  .contactUSText {
    font-size: 13px;
    margin-left: 20px;
  }
  .forgotPasswordDiv {
    margin-left: 30px;
  }
  iframe {
    max-width: 100% !important;
    height: auto !important;
    transform: scale(0.9);
    transform-origin: top left;
    margin-left: 10%;
  }
  .termAndCondition {
    font-size: 12px !important;
  }
  .termAndConditionSecondRegister {
    margin-top: 9px !important;
    margin-left: 100px;
    font-size: 12px;
  }
}

@media (min-width: 425px) and (max-width: 600px) {
  .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height for vertical centering */
    width: 100%;
    flex-direction: column;
  }

  .right-form {
    width: 100% !important;
    max-width: 500px;
    margin: 0 auto !important;
    padding: 1.5rem;
    /* overflow: hidden !important; */
    margin-left: 0px !important;
  }
  img.logoImage {
    max-height: 40px;
    margin-right: 6px;
    width: 200px;
    height: 30px;
  }

  .left-slider {
    display: none;
  }
  .contactUSText {
    font-size: 14px;
    margin-left: 60px;
  }

  .companyCode {
    flex: 0 0 30%;
  }
  .companyPhoneNoDiv {
    flex: 0 0 70%;
  }
  input#companyPhoneNo {
    width: 118%;
  }
  input#phone {
    width: 118%;
  }
  .reCaptchaDiv {
    margin-left: 30px;
  }
  .termAndConditionSecondRegister {
    margin-top: 9px !important;
    margin-left: 106px;
  }

  .forgotPasswordDiv {
    margin-left: 30px;
  }

  iframe {
    max-width: 100% !important;
    height: auto !important;
    transform: scale(1.01);
    transform-origin: top left;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height for vertical centering */
    width: 100%;
    flex-direction: column;
  }

  .right-form {
    width: 100% !important;
    /* max-width: 500px; */
    margin: 0 auto !important;
    padding: 1.5rem;
    /* overflow: hidden !important; */
    margin-left: 0px !important;
  }
  img.logoImage {
    max-height: 40px;
    margin-right: 6px;
    width: 200px;
    height: 30px;
  }

  .left-slider {
    display: none;
  }

  .loginDiv {
    margin: -20px;
  }
  .termAndConditionSecondRegister {
    margin-top: 17px !important;
    margin-left: 8px;
    font-size: 12px !important;
  }
  .termAndCondition {
    font-size: 12px !important;
  }
  iframe {
    max-width: 100% !important;
    height: auto !important;
    /* transform: scale(1.80); */
    transform-origin: top left;
    /* margin-left: 40px; */
  }
  .registerForm {
    padding: 0px;
  }
  input#phone,
  input#companyPhoneNo {
    width: 100%;
    margin-left: 16px;
  }

  label.form-label.confirmPasswordLabel {
    font-size: 12px !important;
  }
  .phoneNoError {
    margin-left: 20px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1243px) {
  .forgotPasswordDiv {
    margin-left: 25px;
  }
  .termAndConditionSecondRegister {
    margin-top: 10px !important;
    margin-left: 106px;
    font-size: 13px;
  }
}

@media (max-width: 1245px) {
  .termAndConditionSecondRegister {
    margin-top: 20px !important;
    margin-left: 9px;
    font-size: 13px;
  }
}
