.policyFromCardDiv.card {
  margin-top: 20px;
  width: 80% !important;
}

.policyFormTitle {
  font-size: 18px;
  font-weight: 600;
}

form.policyCreateMainForm {
  display: grid;
  justify-content: center;
  align-items: center;
}

.bar-0 {
  margin-left: 130px !important;
  margin-right: 60px !important;
}

.bar-1 {
  margin-right: -50px !important;
}

.bar-policy-0 {
  margin-left: 130px !important;
  margin-right: 38px !important;
}
.bar-policy-1 {
  margin-right: -50px !important;
}

.d-flex.justify-content-between.w-70.stepbarwidth {
  width: 70% !important;
}

form.PolicyCreateForm {
  padding: 20px 100px;
}

.policy-error {
  font-size: 13px;
  font-weight: 600;
  margin-top: -7px;
}

.loginInputDiv.policyValue {
  margin-bottom: 20px !important;
}

span.h6.ruleTitle {
  margin: 0px;
  font-weight: 600;
  color: #0e2241;
}

#rest-break-1:focus,
#accept-break:focus,
#reject-break:focus,
input.form-check-input:focus {
  box-shadow: none !important;
}

/* Style for the ruleDesc container */
.ruleDesc,
.violationDesc {
  display: flex; /* Enable flexbox */
  flex-wrap: wrap; /* Allow content to wrap if necessary */
  font-size: 13px; /* Set font size */
  align-items: center; /* Vertically align items */
  justify-content: flex-start; /* Align items to the left */
  background: #80808012; /* Background color */
  padding: 10px 10px; /* Padding around the content */
  gap: 5px 0px; /* Space between elements */
  border-radius: 10px;
}

/* Style for the ruleDesc container */
.violationDesc1 {
  display: flex; /* Enable flexbox */
  flex-wrap: wrap; /* Allow content to wrap if necessary */
  font-size: 13px; /* Set font size */
  align-items: center; /* Vertically align items */
  justify-content: flex-start; /* Align items to the left */
  background: #80808012;
  padding: 10px 10px; /* Padding around the content */
  gap: 5px 0px; /* Space between elements */
  border-radius: 10px;
}

/* Ensure each dropdown stays in a row */
.hourDiv {
  display: flex; /* Use flexbox for dropdowns */
  flex-direction: column; /* Stack the dropdown options vertically */
  margin: 5px 0; /* Add margin between dropdowns */
}

/* Style for the text (span) in between dropdowns */
.ruleDesc span,
.violationDesc span {
  white-space: nowrap; /* Prevent the text from wrapping */
  margin: 0 5px; /* Space between the text and the dropdown */
}

.policyFormCard.card-body {
  padding: 20px 10px;
}

.dropdownList {
  font-size: 12px;
}

.rulesSubDesc {
  padding: 0px 50px;
  font-size: 13px;
}

.formCheckLabel label.form-check-label {
  font-size: 12px;
}

h6.ruleheadTag {
  font-size: 14px;
  font-weight: 600;
}

.formCheckLabel.form-check {
  margin-left: 30px !important;
  margin-top: 0px !important;
}

.rulesSubDesc1 {
  display: flex;
  align-items: center;
  background: #80808012;
  justify-content: center;
  padding: 4px 10px;
  border-radius: 8px;
}

#rest-break-1,
#accept-break,
#reject-break {
  border: 1px solid #7b7e81;
}

input.form-check-input {
  border: 1px solid #7b7e81;
}

button.previousBtn.btn.btn-primary {
  padding: 0px 10px !important;
  height: 31px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 3px !important;
  margin-left: 20px;
  background: #2d64bc;
}

button.previousBtn.btn.btn-primary:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.15);
}

button.previousBtn.btn.btn-light:hover {
  /* transform: translateY(-5px); */
  background: #f3f3f3;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
}

button.previousBtn.btn.btn-light {
  padding: 0px 10px !important;
  height: 31px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 3px !important;
  /* background: #f2f6fe; */
  color: #2d64bce6;
  background: #f3f3f3;
}

.stepTitle.text-primary {
  font-weight: 600;
}

.ruleCreationDiv {
  background: #ffff !important;
  border-radius: 10px !important;
  padding: 10px;
  box-shadow: 1px 2px 8px 2px rgba(0, 0, 0, 0.15);
}

.durationSelectedValue,
.violationSpan {
  padding: 0px 5px;
  font-size: 12px;
  font-weight: 600;
  background: #ebf2ff !important;
  color: #2d64bc;
}

.durationSelectedValue:hover {
  font-weight: 600 !important;
  background: #ebf2ff !important;
  color: #2d64bc !important;
  border: none !important;
}

.rule-custom-scrollbar {
  overflow-x: auto;
  scrollbar-width: thin;
  /* scrollbar-color:  #e3e3e3 transparent;  */
}

.rule-custom-scrollbar::-webkit-scrollbar {
  width: 1px; /* Set the width of the scrollbar */
}

.row.addNewRuleDiv {
  justify-content: end;
  align-items: center;
  text-align: end;
}

button.btn.addNewRuleBtn {
  border-radius: 4px;
  background: #4375d8 !important;
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 8px;
}

.deleteRuleBtn {
  border-radius: 4px;
  /* background: #dd5757f2 !important; */
  color: #dd5757f2;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 8px;
}

.deleteRuleBtn:hover {
  color: #de2d2df2;
}

.deleteRuleBtn:focus {
  color: #de2d2df2 !important;
  border: none;
}

button.btn.addNewRuleBtn:focus {
  color: white;
}

.step-line-container {
  position: relative;
  height: 2px;
  background-color: #ccc; /* Line color */
  margin-top: 10px;
  margin-bottom: 10px;
}

.step-line {
  width: 100%; /* Adjust this to represent the current step progress */
  height: 2px;
  background-color: #007bff; /* Line color for active step */
}

.inputWithBottomBorder {
  border: none;
  border-bottom: 1.5px solid #c5c5c5;
  outline: none;
  font-size: 13px;
  padding: 5px;
  /* margin-left: 10px; */
  border-radius: 3px;
  font-weight: 700;
  background: #ffff;
  width: 100%;
}

.deleteRuleDiv {
  text-align: end;
}

.deleteMgeHeader {
  background: #4071d4 !important;
}

.deleteYesBtn {
  background: #4071d4d4 !important;
  border: none !important;
  padding: 5px 20px !important;
}

.deleteNoBtn {
  background: #b3b3b3 !important;
  border: none !important;
  padding: 5px 20px !important;
}

.modal-title {
  font-size: 1.2rem;
  /* font-weight: 600; */
}

.modal-body-desc {
  font-size: 1.1rem !important;
}

.termsAndConditionTitle {
  font-size: 0.9rem;
  font-weight: 550;
}

ul.termsDesc {
  font-size: 0.9rem;
}

.agreeDiv label.form-check-label {
  font-size: 0.9rem;
}

span.text-danger.bold.violationText {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0px;
  margin-left: 20px;
}

.rule_validation {
  margin-top: 2px !important;
  margin-left: 10%;
}

.rule_violation_validation {
  margin-top: 2px !important;
  margin-left: 3%;
}

.policy-input {
  width: 100% !important;
}

.stepBarLine {
  padding-left: 30px;
  padding-right: 210px;
}

.backIcon {
  /* background: red; */
  padding: 6px;
  border-radius: 50%;
  color: #2d64bc;
  font-size: 20px;
  /* border: 1px solid #b7abab; */
}

.violationName::placeholder {
  color: rgb(212, 77, 77); /* Set the placeholder text color to red */
  opacity: 1; /* Ensure full opacity for visibility */
}

.violationName:focus::placeholder {
  color: rgb(212, 77, 77); /* Optionally, make it darker when focused */
}

/* Styling the date picker popup */
.styled-date-picker::-webkit-calendar-picker-indicator {
  display: none; /* Remove the default calendar icon */
}

.styled-date-picker:focus {
  outline: none; /* Remove the default outline */
}

.styled-date-picker::after {
  content: "📅"; /* Optional, add custom calendar icon */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  pointer-events: none; /* Prevent interaction with the icon */
}
/* Basic styles for the date input */
input[type="date"] {
  padding: 9px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 200px;
}

.policyMainForm {
  margin-bottom: 0px !important;
}

.policy-form-error {
  margin-top: 3px;
}

.policy-name-error {
  margin-top: -22px;
}

/* Desktop Screen  */

@media screen and (min-width: 1600px) and (max-width: 1600px) {
  span.text-danger.bold.violationText {
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 1280px) {
  .stepBarLine {
    padding-left: 0;
    padding-right: 180px;
  }
}

@media screen and (max-width: 800px) {
  .stepBarLine {
    padding-left: 0;
    padding-right: 180px;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  form.PolicyCreateForm {
    padding: 20px 100px; /* Adjust padding for smaller screens */
    width: 500px;
    max-width: 500px;
    margin-left: -50px;
  }
  .bar-0 {
    margin-left: 105px !important;
    margin-right: 40px !important;
  }
  .bar-1 {
    margin-right: -74px !important;
  }
  .bar-policy-0 {
    margin-left: 105px !important;
    margin-right: 40px !important;
  }
  .bar-policy-1 {
    margin-right: -74px !important;
  }
  .premiseAddMainDiv {
    padding: 30px 30px;
  }
  .timeOpenDiv {
    margin-left: 29px !important;
  }
  .policyTermAndCondition{
    width: 400px;
  }
  .mainRuleCreateDiv{
    margin-left: -34px;
  }
  .ruleCreationDiv{
    margin-left: 35px;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  form.PolicyCreateForm {
    padding: 20px 100px; /* Adjust padding for smaller screens */
    width: 500px;
    max-width: 500px;
    margin-left: -50px;
  }
  .bar-0 {
    margin-left: 105px !important;
    margin-right: 40px !important;
  }
  .bar-1 {
    margin-right: -74px !important;
  }
  .bar-policy-0 {
    margin-left: 105px !important;
    margin-right: 40px !important;
  }
  .bar-policy-1 {
    margin-right: -74px !important;
  }
  .premiseAddMainDiv {
    padding: 30px 30px;
  }
  .timeOpenDiv {
    margin-left: 29px !important;
  }
  .policyTermAndCondition{
    width: 400px;
  }
  .mainRuleCreateDiv{
    margin-left: -34px;
  }
  .ruleCreationDiv{
    margin-left: 35px;
  }
}

@media (min-width: 425px) and (max-width: 600px) {
  form.PolicyCreateForm {
    padding: 20px 100px; /* Adjust padding for smaller screens */
    width: 500px;
    max-width: 500px;
    margin-left: -50px;
  }
  .bar-0 {
    margin-left: 105px !important;
    margin-right: 40px !important;
  }
  .bar-1 {
    margin-right: -74px !important;
  }
  .bar-policy-0 {
    margin-left: 105px !important;
    margin-right: 40px !important;
  }
  .bar-policy-1 {
    margin-right: -74px !important;
  }
  .premiseAddMainDiv {
    padding: 30px 30px;
  }
  .timeOpenDiv {
    margin-left: 29px !important;
  }
  .policyTermAndCondition{
    width: 400px;
  }
  .mainRuleCreateDiv{
    margin-left: -34px;
  }
  .ruleCreationDiv{
    margin-left: 35px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  form.PolicyCreateForm {
    padding: 20px 60px; /* Adjust padding for smaller screens */
  }
  .bar-0 {
    margin-left: 116px !important;
    margin-right: 42px !important;
  }
  .bar-1 {
    margin-right: -66px !important;
  }
  .bar-policy-0 {
    margin-left: 122px !important;
    margin-right: 42px !important;
  }
  .bar-policy-1 {
    margin-right: -56px !important;
  }
}

@media (min-width: 800px) and (max-width: 900px) {
  .bar-0 {
    margin-left: 127px !important;
    margin-right: 40px !important;
  }
  .bar-1 {
    margin-right: -53px !important;
  }
  .bar-policy-0 {
    margin-left: 136px !important;
    margin-right: 40px !important;
  }
  .bar-policy-1 {
    margin-right: -40px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  form.PolicyCreateForm {
    padding: 20px 50px;
  }
  .bar-0 {
    margin-left: 100px !important;
    margin-right: 35px !important;
  }
  .bar-1 {
    margin-right: -80px !important;
  }
  .bar-policy-0 {
    margin-left: 112px !important;
    margin-right: 35px !important;
  }
  .bar-policy-1 {
    margin-right: -67px !important;
  }
}

@media (min-width: 1441px) {
  form.PolicyCreateForm {
    padding: 20px 160px;
  }
}

/* @media screen and (min-width: 1600px){
  form.PolicyCreateForm {
    padding: 20px 160px;
  }
} */
