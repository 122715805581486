
.forgot-container{
    background: rgba(255, 255, 255, 1) !important; 
}

.forgot-password-title {
    color: #0e2241;
    font-size: 23px;
    font-weight: 600;
}

.forgot-card {
    box-shadow: 2px 4px 20px 8px rgba(0, 0, 0, 0.08);
    background: rgba(255, 255, 255, 1);
    width: 500px !important;
    height: 400px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.forgot-password-desc {
    color: rgba(98, 100, 101, 1);
    font-size: 12px;
}

/* .forgot-password-form {
    display: grid;
    justify-content: center;
    align-items: center;
} */

.forgot-password-form {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    margin: 0% 15%;
}

/* Styling the input field */
.forgot-input {
    border: none;
    border-bottom: 1.5px solid #ccc;
    padding: 9px;
    border-radius: 2px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    background: rgba(216, 216, 216, 0.3);
    outline: none;
    color: rgba(0, 0, 0, 1);
    transition: border-color 0.3s ease-in-out;
}
  
.forgot-input:focus {
    outline: none;
    border-bottom-color: #00c9a7 !important; /* Change bottom border color on focus */
    box-shadow: 0 1px 0 0 rgba(0, 201, 167, 0.2); /* Optional: subtle shadow effect */
}

    .forgot-button{
        border: 0;
        padding: 7px 10px !important;
        color: #fff;
        width: 100% !important;
        font-weight: 600;
        transition: box-shadow 0.3s ease, transform 0.3s ease;
        border-radius: 4px;
        background: #2d64bc !important;
    }

  .forgot-div {
    padding-bottom: 10%;
  }

  .forgot-main-div {
    width: 100%;
  }

  .forgot-close {
    padding: 4%;
}

img.closeImage {
    width: 16px;
    border: none !important;
}

.forgot-password-error{
    font-size: 12px;
    font-weight: 600;
    margin-top: -1rem;
    margin-bottom: 10px;
    margin-left: 10px;
}

.forgot-form-input{
    width: 100%;
}