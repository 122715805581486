/* src/components/DashboardLayout.css */
.dashboard-layout {
  display: flex;
}

.main-content.dashboardMain {
  margin-left: 210px; /* Sidebar width */
  flex-grow: 1; /* Allow the main content to fill remaining space */
  background: rgba(242, 242, 242, 1);
}

.content {
  padding: 3% 3% 3% 3%;
  /* margin-top: 60px;  */
  min-height: calc(100vh - 60px); /* Minimum height */
}

.contentDiv {
  margin-right: 20%;
}

.buttonValue {
  background: #2d64bc;
  /* background: linear-gradient(90.61deg, #33BB78 0%, #73ADE3 103.46%); */
  border: none !important;
  border-radius: 8px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  width: 40%;
}

img.addIcon {
  width: 12px;
  margin-bottom: 1%;
}

/* src/components/DashboardLayout.css */

.selectDiv {
  width: 50% !important;
  /* border: 1px solid #ccc !important; */
  /* border-radius: 8px !important;  */
  background-color: #fff !important; /* White background for the dropdown */
  transition: border-color 0.3s, box-shadow 0.3s !important; /* Smooth transition */
  appearance: none !important; /* Remove default dropdown arrow */
  position: relative !important; /* For the custom arrow */
  font-size: 12px !important;
  border: none !important;
}

.selectDiv:focus {
  /* border-color: #33BB78 !important; */
  box-shadow: 0 0 5px rgba(51, 187, 120, 0.5) !important;
}

.selectDiv option {
  background-color: #fff; /* White background for options */
  color: #333; /* Dark text color */
  font-size: 12px; /* Reduced font size for options */
  padding: 5px 10px; /* Optional padding for options */
}

/* Option hover state */
.selectDiv option:hover {
  background-color: #f0f0f0; /* Light gray on hover */
  color: #000; /* Darker text color */
}

/* Add a custom arrow */
.selectDiv::after {
  content: "";
  position: absolute !important;
  right: 15px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  border: solid transparent !important;
  border-width: 5px 5px 0 !important;
  border-top-color: #ccc !important; /* Color of the dropdown arrow */
  pointer-events: none !important; /* Prevent clicking on the arrow */
}

/* Hover state for dropdown */
.selectDiv:hover {
  border-color: #33bb78 !important; /* Change border color on hover */
}

/* Style the options dropdown */
.selectDiv option {
  background-color: #fff !important; /* White background for options */
  color: #333 !important; /* Dark text color */
}

/* Option hover state */
.selectDiv option:hover {
  background-color: #f0f0f0 !important; /* Light gray on hover */
  color: #000 !important; /* Darker text color */
}

.userActionItem {
  width: 85px !important;
}
.existUserPhoneCode {
  width: 80% !important;
}

.card-image-round-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image-option-div {
  display: grid;
  justify-content: center;
  align-items: center;
}

.card-mainDiv {
  display: grid !important;
}
.cardImageTop {
  background: #f2f6fe;
  margin-top: 4px;
  margin-bottom: -10px;
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
}

.downloadIcon {
  margin-right: 4px;
}
.downloadCSV {
  margin-left: 15px;
}

.inActiveUser{
  background: #d3d3d3;
}

.deactivateUser {
  background: #FFC1C3;
}


.dot-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.dot-loader span {
  width: 12px;
  height: 12px;
  background-color: #0d6efd; /* Bootstrap Primary */
  border-radius: 50%;
  animation: dot-flash 1.4s infinite ease-in-out both;
}

.dot-loader span:nth-child(1) {
  animation-delay: -0.32s;
}

.dot-loader span:nth-child(2) {
  animation-delay: -0.16s;
}

.dot-loader span:nth-child(3) {
  animation-delay: 0;
}


@keyframes dot-flash {
  0%,
  80%,
  100% {
    opacity: 0.3;
    transform: scale(0.8);
  }
  40% {
    opacity: 1;
    transform: scale(1.2);
  }
}

button.btn.policyAddBtn {
  border-radius: 4px;
  background: #4375d8 !important;
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 8px;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .userAddBtn {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 600px) {
  .userAddBtn {
    margin-bottom: 10px !important;
  }
}
