.timingdateRangeModal {
  right: 50px;
}

.totalHoursDetails {
  font-size: 13px !important;
}

.hoursTitle {
  font-size: 13px !important;
}

.expandBtn {
  font-size: 13px;
  font-weight: 600;
  color: gray;
}

.expandMainBtn {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.timeMainTitle {
  font-size: 15px;
}

.timeThMainDiv {
  justify-content: center !important;
}

.hoursDetails {
  font-size: 17px !important;
}

.pdfExportImage {
  width: 30px;
  margin-right: 6px;
  cursor: pointer;
}

.dot-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.dot-loader span {
  width: 12px;
  height: 12px;
  background-color: #0d6efd; /* Bootstrap Primary */
  border-radius: 50%;
  animation: dot-flash 1.4s infinite ease-in-out both;
}

.dot-loader span:nth-child(1) {
  animation-delay: -0.32s;
}

.dot-loader span:nth-child(2) {
  animation-delay: -0.16s;
}

.dot-loader span:nth-child(3) {
  animation-delay: 0;
}

@keyframes dot-flash {
  0%,
  80%,
  100% {
    opacity: 0.3;
    transform: scale(0.8);
  }
  40% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .rdrDateRangePickerWrapper {
    width: 775px !important;
  }

  .dateRangeModal {
    right: 155px !important;
  }

  .table-responsive {
    position: relative;
    max-height: 850px !important; /* Adjust as needed */
    overflow-y: auto; /* Enables vertical scrolling */
  }
  .filterMainDiv{
    max-height: 850px !important;
  }
  .userFitlerDiv{
    max-height: 600px !important;
  }
}


@media (min-width: 425px) and (max-width: 600px) {
  .rdrDateRangePickerWrapper {
    width: 375px !important;
  }

  .dateRangeModal {
    right: 0px !important;
  }

  .modal-dialog {
    max-height: 90vh; /* Prevents modal from being too large */
    margin: 0.5rem; /* Adds spacing on small screens */
  }

  .modal-content {
    max-height: 85vh; /* Ensures the modal content is scrollable */
    overflow-y: auto; /* Enables scrolling */
  }

  .modal-body {
    max-height: 60vh; /* Prevents excessive height */
    overflow-y: auto; /* Allows scrolling inside modal */
  }
}
