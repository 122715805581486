.tab-menu {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
  color:gray;
  box-shadow: 2px 4px 20px 8px rgba(0, 0, 0, 0.08);
}

.main-content.policyMain {
  margin-left: 210px; /* Sidebar width */
  flex-grow: 1; /* Allow the main content to fill remaining space */
  background: rgba(242, 242, 242, 1);
}

.tab-button.active {
  background-color: #2d64bc;
  color: white;
  border-color: #2d64bc;
}

.tab-button:hover {
  background-color: #0056b3;
  color: white;
}

.tab-content {
  padding: 20px;
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  background-color: #9993930f;
  min-height: calc(100vh - 60px);
}

.shadow-sm.p-4.card {
  background: #ffffff8f;
}



/* Premise List Css  */

.settings-layout {
  display: flex;
}

.main-content.settingsMain {
  margin-left: 230px; /* Sidebar width */
  flex-grow: 1; /* Allow the main content to fill remaining space */
  background: rgba(242, 242, 242, 1);
  /* background: rgba(242, 242, 242, 1); */
}

.content {
  padding: 3% 3% 3% 3%;
  /* margin-top: 60px;  */
  min-height: calc(100vh - 60px); /* Minimum height */
}


.contentDiv {
  margin-right: 20%;
}

.buttonValue {
  background: #2d64bc;
  /* background: linear-gradient(90.61deg, #33BB78 0%, #73ADE3 103.46%); */
  border: none !important;
  border-radius: 8px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  width: 40%;
}

img.addIcon {
  width: 12px;
  margin-bottom: 1%;
}


.settings-layout {
  display: flex;
  min-height: 100vh;
}

.settingsMain {
  flex: 1;
  /* padding: 20px; */
}

.contentHeader {
  font-size: 17px;
  font-weight: bold;
}

.card.setting-card {
  height: 226px;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s;
  width: 100%;
  max-width: 280px; /* Limit card width */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.card.setting-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.card-img-top {
  height: 150px;
  object-fit: cover;
}

.setting-card .card-body {
  text-align: center;
  padding: 15px;
}

.card-title {
  font-size: 15px;
  font-weight: 600;
  /* margin-bottom: 10px; */
}

.card-text {
  font-size: 14px;
  color: #666;
}


/* .settingDetailsDiv {
  padding: 0px 40px;
} */


.card-image-div {
  height: 150px;
  object-fit: none;
  background:#eaf1ff; 
  /* background: #f2f6fe; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-img-top {
  width: 100px !important;
  height: 100px;
  /* object-fit: none; */
  background: #f2f6fe;
  border-radius: 10px;
}

@media (min-width: 320px) and (max-width: 375px) {
  .card-img-top {
    height: 80px;
    width: 70px !important;
  }
  .card-image-div {
    height: 130px;
  }
  .card.setting-card {
    height: 190px;
  }
}


@media (min-width: 375px) and (max-width: 425px) {
  .card-img-top {
    height: 80px;
    width: 70px !important;
  }
  .card-image-div {
    height: 130px;
  }
  .card.setting-card {
    height: 200px;
  }
}

/* Responsive Adjustments */
@media (min-width: 425px) and (max-width: 600px) {
  .card-img-top {
    height: 100px;
  }
  .card-title {
    font-size: 16px;
  }
  .card-text {
    font-size: 13px;
  }
  .card-image-div {
    height: 130px;
  }
  .card.setting-card {
    height: 200px;
  }
}


/* Policy List Css  */

.noPolicyDiv {
  border-radius: 10px;
  /* background: #f4f4ff; */
  
}


.policyCard.card-body {
  height: 120px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  opacity: 0.8;
}

.card-border {
  border: 2px solid #ddd; /* Border for the entire card */
  position: relative; /* This allows positioning the status badge in the top-right corner */
}

.policyCard .card-text {
  font-size: 12px;
  color: #555;
}

.policyCard .card-title {
  font-weight: 550 !important;
  margin-bottom: 10px;
}

.status-badge {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 12px;
  font-size: 12px;
  color: white;
  border-radius: 2px !important;
  font-weight: bold;
}

.status-badge.current {
  background-color: #00c600cc;
  border-radius: 2px !important;
}

.status-badge.expired {
  background-color: #e02d2dd9;
  border-radius: 2px !important;
}

.status-badge.upcoming {
  background-color: orange;
  border-radius: 2px !important;
}

.policyMainDiv {
  border: none;
}

.policyMainDiv1 {
  padding: 0px 10px;
}

.arrowDiv {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.arrowIcon {
  /* background: red; */
  padding: 6px;
  border-radius: 50%;
  border: 1px solid #b7abab;
}

.policyAddDiv {
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
}

button.btn.policyAddBtn {
  border-radius: 4px;
  background: #4375d8 !important;
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 8px;
}
button.btn.policyAddBtn:focus {
  color: white;
}

.policyAddRow {
  text-align: end;
  margin-right: 10px;
}


button.btn.policyAddBtn:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 10px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
  background: #2d64bc !important;
}