.userPhoneCode {
  margin-left: 0px !important;
  width: 80%;
}

.userActionDiv {
  margin-bottom: 4px !important;
}

/* .enableInviteCheckBox{
  margin-top: -2px;
  margin-right: -5px;
}
.enableInvite {
  font-size: 14px;
  color: #4a90e2 !important;
  font-weight: 600;
} */

.cursor-not-allowed {
  cursor: not-allowed !important;
  opacity: 0.6; /* Optional: Reduce opacity to indicate it's disabled */
}

.userImageMainDiv {
  margin-bottom: -15px !important;
}
.UserImageMainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.userStatus {
  margin-top: 18px;
}

.switchConfig {
  margin-right: 27px;
}

.switchConfig1 {
  margin-right: 23px;
}

.switchConfig2 {
  margin-right: 13px;
}

.bar-user-0 {
  margin-left: 280px !important;
  margin-right: 100px !important;
}

.userSwitch {
  margin-left: 10px;
}

.user-label {
  font-size: 14px;
  color: #3d91ec !important;
  font-weight: 600;
  text-align: center;
  margin-top: 8px;
}

.hiddenError {
  display: none;
}

.label-switch {
  font-size: 14px;
  font-weight: 600;
  color: #6c757d;
  background-color: #fff;
  padding: 0 0.25rem;
  /* pointer-events: none; */
  transition: all 0.3s ease;
  max-width: 460px;
}

.mainSwitchDiv {
  font-size: 13px;
}

.userInformationDiv {
  padding: 40px 0px 40px 40px;
}

.generalConfig {
  margin-top: -15px !important;
}

.userCreateForm {
  padding: 20px 30px 10px 0px;
}

.changeMobileNoDiv{
  text-align: end;
}

.changeMobileNo{
  font-size: 13px;
  margin: 0px !important;
  padding: 0px !important;
}

@media (max-width: 575px) {
  .userFirstName {
    margin-bottom: 20px !important;
  }
  .userPhoneNo {
    width: 245px;
  }
  .userInformationDiv {
    padding: 30px 0px 0px 0px;
  }
  .userCreateForm {
    padding: 20px 0px 0px 10px;
  }
  .informationDetails {
    margin-bottom: 20px;
  }
  .chooseFileBtn {
    padding: 0px 3px !important;
  }
  .userFormlabel {
    font-size: 13px !important;
  }
  .InputFieldDiv {
    width: 175px !important;
  }
  .informationLabel {
    font-size: 13px;
  }
  .informationDesc {
    font-size: 12px !important;
  }
  .premiseDetailsSubDiv {
    width: 500px;
    margin-left: -40px;
  }
  .configMainDiv {
    margin-bottom: 15px !important;
  }
  .userLastNameLabel {
    margin-right: 0px !important;
  }
  .userPhoneNoLabel {
    margin-right: -20px !important;
  }
  .userPhoneNoField {
    width: 120px !important;
  }
  .bar-user-0 {
    margin-left: 130px !important;
    margin-right: -50px !important;
  }
  .CaptureImageLabel{
    margin-bottom: 10px !important;
  }
}

@media (min-width: 425px) and (max-width: 600px) {
  .userMainForm {
    padding: 20px 100px !important;
  }
  /* .bar-user-0 {
    margin-left: 150px !important;
    margin-right: -33px !important;
  } */
}

@media (min-width: 601px) and (max-width: 1023px) {
  .userMainForm {
    padding: 20px 60px !important;
  }
  .bar-user-0 {
    margin-left: 170px !important;
    margin-right: -10px !important;
  }
  .userInformationDiv {
    padding: 30px 0px 0px 0px;
  }
  .userCreateForm {
    padding: 20px 0px 0px 10px;
  }
  .informationDetails {
    width: 120px;
  }
  .chooseFileBtn {
    padding: 0px 3px !important;
  }
  .userFormlabel {
    font-size: 13px !important;
  }
  .InputFieldDiv {
    width: 220px !important;
  }
  .configLabel {
    width: 120px;
  }
  .informationLabel {
    font-size: 13px;
  }
  .informationDesc {
    font-size: 12px !important;
  }
}

@media (min-width: 800px) and (max-width: 900px) {
  .userMainForm {
    padding: 20px 100px !important;
  }
  .bar-user-0 {
    margin-left: 180px !important;
    margin-right: 0px !important;
  }
  .userInformationDiv {
    padding: 20px 0px 0px 0px;
    margin-top: 10px !important;
  }
  .generalConfig {
    margin-top: -15px !important;
  }
  .userCreateForm {
    padding: 20px 10px 0px 0px;
  }
  .informationDetails {
    width: 133px;
  }
  .chooseFileBtn {
    padding: 0px 3px !important;
  }
  .InputFieldDiv {
    width: 250px !important;
  }
  .configLabel {
    width: 198px;
  }
  .CaptureImageDiv{
    margin-bottom: 12px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .userMainForm {
    padding: 20px 260px !important;
  }
  .bar-user-0 {
    margin-left: 240px !important;
    margin-right: 60px !important;
  }
}

@media (min-width: 1441px) and (max-width: 1599px) {
  .userMainForm {
    padding: 20px 330px !important;
  }
  /* .bar-user-0 {
    margin-left: 100px !important;
    margin-right: -18px !important;
  } */
}

@media (min-width: 1441px) {
  .userMainForm {
    padding: 20px 330px !important;
  }
}

/* @media (min-width: 1441px) and (max-width: 1px) {
  .bar-user-0 {
    margin-left: 265px !important;
    margin-right: 82px !important;
  }
} */

@media (min-width: 1800px) {
  .bar-user-0 {
    margin-left: 320px !important;
    margin-right: 140px !important;
  }
}

@media (min-width: 3840px) {
  .bar-user-0 {
    margin-left: 320px !important;
    margin-right: 140px !important;
  }
}
