.rejectedBtn {
    border-radius: 4px;
    font-weight: bold;
    padding: 2px 5px;
    transition: all 0.3s ease;
    font-size: 11px;
    background-color: #cd4e48 ;
  }
  
  .rejectedBtn:hover {
    background-color: #e63a31e6; /* Darker shade of blue */
    color: #fff; /* Ensures text is white */
    transform: scale(1.05); /* Slightly enlarges button on hover */
  }

  .thMainDiv{
    justify-content: center;
  }

  .rejectLeaveBtn{
    border: none !important;
    padding: 5px 20px !important;
  }