/* Fixed Footer */
.table-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: #333;
  color: white;
  z-index: 2;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
}

.tableFooter td {
  background-color: #f6f6f6 !important;
  font-size: 13px;
  color: #868686;
}

td.missedEntry {
  background: #ff999c;
}

td.addNewEntry {
  background: #c9f1fc;
}

.hidden-out-row {
  display: none !important;
}

.hidden {
  display: none;
}

input#Numberic_format {
  margin-left: -36px;
  margin-top: 5px;
  margin-right: 5px;
}

.missedTimeLabel {
  font-size: 14px;
  font-weight: 600;
  color: #6c757d;
  text-align: center;
  margin-bottom: 5px;
}

label.form-check-label {
  cursor: pointer;
}

input#show-violation {
  cursor: pointer;
}
