.loginInputDiv {
  width: 100%;
}
.loginDiv {
  margin: 0 50px;
}

.loginBtn {
  border: 0;
  padding: 7px 10px !important;
  color: #fff;
  width: 100% !important;
  font-weight: 600;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  border-radius: 4px;
  background: #2d64bc !important;
}
.loginBtn:hover {
  color: white !important;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15), 0 8px 10px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}

.signupLink {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  font-size: 14px;
}

a.SignUpLink {
  text-decoration: none;
  font-weight: 550;
  /* margin-right: 1.5rem; */
}

.login-right-form {
  width: 50%;
  overflow-y: scroll; /* Enable scrolling */
  padding: 2rem;
  background-color: #ffffff;
  z-index: 10;
  scrollbar-width: none; /* For Firefox */
  margin-left: 50%;
  scroll-behavior: smooth;
  /* height: 100vh; */
}

.login-right-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-input {
  width: 300px;
  border: none;
  padding: 0.6rem 0.75rem;
  font-weight: 600;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  font-size: 0.9rem;
  outline: none;
  background: none;
  color: #444444;
  transition: border-color 0.3s, box-shadow 0.3s;
}
.login-form-input:focus {
  /* border-color: #4a90e2; */
  border-color: #2d64bc;
  /* box-shadow: 0 0 2px 4px rgba(74, 144, 226, 0.2); */
}

.login-form-input:focus + .form-label,
.login-form-input:not(:placeholder-shown) + .form-label {
  top: -0.1rem;
  font-size: 14px;
  color: #4a90e2;
}

.loginBtn {
  width: 300px !important;
}

.login-toggle-password {
  position: absolute;
  top: 8px;
  left: 275px;
}

/* .termAndConditionSecond {
  margin-top: 20px !important;
  margin-left: 8px;
} */

.termsAndConditionsLoginError {
  margin-top: 1.8rem !important;
  width: 90%;
  /* margin-top: 1rem !important; */
  margin-left: 20px;
}

.forgotPasswordDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px !important;
}

.forget-link {
  color: #6c757d;
  justify-content: end;
  display: flex;
  font-weight: 500;
  font-size: 13px;
  text-decoration: none;
  margin: 10px 10px 0px 0px;
}
.forget-link:hover {
  color: #2d64bc !important; /* Color on hover */
  font-weight: 500;
}

.login-container {
  display: flex;
  min-height: 100vh;
}

.login-right-form {
  width: 50%;
  padding: 2rem;
  background-color: #ffffff;
  z-index: 10;
  margin-left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  height: 100vh; /* Full height */
  overflow: hidden; /* Remove scroll */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.termAndConditionLogin {
  font-size: 13px !important;
}

.LoginForm {
  padding: 0 2rem 2rem;
}

@media (min-width: 320px) and (max-width: 375px) {
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height for vertical centering */
    width: 100%;
    flex-direction: column;
    margin-top: 50px;
  }

  .login-right-form {
    width: 170% !important;
    max-width: 400px;
    margin: 0 auto !important;
    padding: 0.5rem;
    overflow: hidden !important;
    margin-left: -80px !important;
  }
  img.logoImage {
    max-height: 40px;
    margin-right: 6px;
    width: 160px;
    height: 25px;
  }
  .left-slider {
    display: none;
  }
  .contactUSText {
    font-size: 12px;
    margin-left: 25px;
  }
  .termAndConditionLogin {
    font-size: 12px !important;
  }
  .termAndConditionSecond {
    font-size: 12px !important;
  }
  .forgotPasswordDiv {
    margin-left: 25px;
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height for vertical centering */
    width: 100%;
    flex-direction: column;
    margin-top: 50px;
  }

  .login-right-form {
    width: 150% !important;
    max-width: 400px;
    margin: 0 auto !important;
    padding: 0.5rem;
    overflow: hidden !important;
    margin-left: -50px !important;
  }
  img.logoImage {
    max-height: 40px;
    margin-right: 6px;
    width: 200px;
    height: 30px;
  }
  .left-slider {
    display: none;
  }
  .contactUSText {
    font-size: 13px;
    margin-left: 20px;
  }
  .termAndConditionLogin {
    font-size: 12px !important;
  }
  .termAndConditionSecond {
    font-size: 12px !important;
  }
  .forgotPasswordDiv {
    margin-left: 25px;
  }
}

@media (min-width: 425px) and (max-width: 600px) {
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height for vertical centering */
    width: 100%;
    flex-direction: column;
    margin-top: 50px;
    overflow: hidden;
  }

  .login-right-form {
    width: 100% !important;
    max-width: 400px;
    margin: 0 auto !important;
    padding: 0.5rem;
    overflow: hidden !important;
    margin-left: -30px !important;
  }
  img.logoImage {
    max-height: 40px;
    margin-right: 6px;
    width: 200px;
    height: 30px;
  }

  .left-slider {
    display: none;
  }
  .contactUSText {
    font-size: 14px;
    margin-left: 60px;
  }
  .termAndConditionLogin {
    font-size: 13px !important;
  }
  .termAndConditionSecond {
    font-size: 13px !important;
  }
  .forgotPasswordDiv {
    margin-left: 25px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height for vertical centering */
    width: 100%;
    flex-direction: column;
    margin-top: 50px;
    overflow: hidden;
  }

  .login-right-form {
    width: 100% !important;
    /* max-width: 400px; */
    margin: 0 auto !important;
    padding: 0.5rem;
    overflow: hidden !important;
  }

  .forgotPasswordDiv {
    margin-left: 0px;
  }
}

@media (min-width: 1025px) and (max-width: 1243px) {
  .login-container {
    margin-top: 50px;
    overflow: hidden;
  }
  .forgotPasswordDiv {
    margin-left: 25px;
  }
}

@media (min-width: 1245px) {
  .termAndConditionSecondRegister {
    margin-top: 20px !important;
    margin-left: 9px;
    font-size: 13px;
  }
}
