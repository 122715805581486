/* src/components/DashboardLayout.css */
.dashboard-layout {
    display: flex;
  }
  
  .main-content.dashboardMain {
    margin-left: 210px; /* Sidebar width */
    flex-grow: 1; /* Allow the main content to fill remaining space */
    background: rgba(242, 242, 242, 1);
  }
  
  .content {
    padding: 3% 3% 3% 3%;
    /* margin-top: 60px;  */
    min-height: calc(100vh - 60px); /* Minimum height */
  }
  
  
  .contentTitle {
    font-size: 15px;
    color: #34495e !important;
    font-weight: bold;
  }

  .contentDiv {
    margin-right: 20%;
}

  .buttonValue {
    background: #2d64bc;
    /* background: linear-gradient(90.61deg, #33BB78 0%, #73ADE3 103.46%); */
    border: none !important;
    border-radius: 8px !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    width: 40%;
  }

  img.addIcon {
    width: 12px;
    margin-bottom: 1%;
  }


  /* src/components/DashboardLayout.css */

.selectDiv {
  width: 50% !important;
  /* border: 1px solid #ccc !important; */
  /* border-radius: 8px !important;  */
  background-color: #fff !important; /* White background for the dropdown */
  transition: border-color 0.3s, box-shadow 0.3s !important; /* Smooth transition */
  appearance: none !important; /* Remove default dropdown arrow */
  position: relative !important; /* For the custom arrow */
  font-size: 12px !important; 
  border : none !important;
}

.selectDiv:focus {
  /* border-color: #33BB78 !important; */
  box-shadow: 0 0 5px rgba(51, 187, 120, 0.5) !important; 
}

.selectDiv option {
  background-color: #fff; /* White background for options */
  color: #333; /* Dark text color */
  font-size: 12px; /* Reduced font size for options */
  padding: 5px 10px; /* Optional padding for options */
}

/* Option hover state */
.selectDiv option:hover {
  background-color: #f0f0f0; /* Light gray on hover */
  color: #000; /* Darker text color */
}

/* Add a custom arrow */
.selectDiv::after {
  content: '';
  position: absolute !important;
  right: 15px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  border: solid transparent !important;
  border-width: 5px 5px 0 !important;
  border-top-color: #ccc !important; /* Color of the dropdown arrow */
  pointer-events: none !important; /* Prevent clicking on the arrow */
}

/* Hover state for dropdown */
.selectDiv:hover {
  border-color: #33BB78 !important; /* Change border color on hover */
}

/* Style the options dropdown */
.selectDiv option {
  background-color: #fff !important; /* White background for options */
  color: #333 !important; /* Dark text color */
}

/* Option hover state */
.selectDiv option:hover {
  background-color: #f0f0f0 !important; /* Light gray on hover */
  color: #000 !important; /* Darker text color */
}



.search-bar {
  max-width: 300px;
  width: 100%;
}

.search-bar input {
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0 0 5px #ffff;
  /* border: 1px solid #ccc; */
}

.search-bar input:focus {
  border-color: #6b8dc4;
  box-shadow: 0 0 5px rgba(45, 100, 188, 0.5);
}

.premiseDetails {
    padding: 20px;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    background-color: #c1c0c00f;
    min-height: calc(100vh - 60px);
}
  
ol.breadcrumb {
  margin-bottom: 0px;
}

.premisesListDiv{
  display: flex;
  justify-content: center;
}

.PremiseCreateBtn {
  padding-right: 20px !important;
}




.premiseActionDropDownMenu {
  position: absolute;
  top:25px;
  right: 0;
  background: white;
  border-radius: 5px;
  padding: 5px;
  z-index: 10;
  display: none;
  border: none;
}

.premiseActionDropDownMenu.show {
  display: block;
}

.premiseActionItem {
  padding: 2px 10px;
  cursor: pointer;
  width: 60px;
  text-align: left;
  font-size: 12px;
  font-weight: 550;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.premiseActionItem:hover {
  background-color: #68a4e15c;
}

.premiseActionItem.text-danger {
  color: #f8d7da;
}

.premiseActionItem.text-danger:hover {
  background-color: #f8d7da;
}
