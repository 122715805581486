/* src/components/TopBar.css */

.top-bar {
  height: 60px;
}

.user-section {
  position: relative; /* Make sure dropdown positions relative to this */
}

.user-section img {
  cursor: pointer;
}

.dropdown-menu.topbar {
  display: block !important;
  position: absolute !important;
  top: 45px !important;
  right: 0 !important;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px !important;
  padding: 5px !important;
  width: 10px !important;
  min-width: 110px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  z-index: 1000 !important;
}

.dropdown-menu.topbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu.topbar li {
  padding: 8px 12px;
  font-size: 12px;
  color: rgba(121, 121, 121, 1);
  cursor: pointer;
}

.dropdown-menu.topbar li:hover {
  background: #2d64bc;
  /* background: linear-gradient(90deg, #33BB78 0%, #73ADE3 100%); */
  color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  /* background-color: #555; */
}
.topBarDiv {
  /* margin: 1% 0 0 0; */
  padding-right: 8px !important;
  /* padding-top: 1.1rem !important; */
  background: #fff;
  height: 60px;
}

.blink-alert {
  animation: slow-blink 2s infinite; /* Slow blinking animation */
}

@keyframes slow-blink {
  0%,
  100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0.5; /* Slightly faded */
  }
}

.blink-alert:hover {
  animation: none; /* Stop blinking on hover */
}

.adminIcon {
  width: 45px;
  height: 45px;
  /* border: 1.9px solid #97a7ff; */
}

.alertDiv {
  font-size: 0.9rem;
  font-weight: 600;
  color: #0e2241 !important;
  margin: 0px 0px !important;
  padding: 5px 20px;
  width: auto;
}

.profileIcon {
  width: auto;
  margin-left: 0px;
}

.verify-btn {
  background-color: #007bff; /* Primary blue */
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.verify-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.verify-btn {
  padding: 1px 20px;
  margin-left: 10px;
  margin-right: 15px;
}

.verifyCloseBtn {
  font-size: 12px !important;
  padding: 11px !important;
  font-weight: bold !important;
}

.companyName {
  font-size: 1.2rem; /* Large font size for prominence */
  font-weight: bold; /* Bold text */
  background: linear-gradient(to right, #007bff, #6610f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  /* animation: fadeIn 2s ease-in-out;  */
  transition: transform 0.3s ease;
  margin-left: 30px;
}

.companyName:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.profileIcon {
  width: auto;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .alertDiv {
    display: none;
  }
  .topBarPremiseDowndown {
    max-width: 210px;
    margin-left: 45px;
  }
  .adminIcon {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  .alertDiv {
    display: none;
  }
  .topBarPremiseDowndown {
    max-width: 265px;
    margin-left: 45px;
  }
  .adminIcon {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 425px) and (max-width: 600px) {
  .alertDiv {
    display: none;
  }
  .topBarPremiseDowndown {
    max-width: 300px;
    margin-left: 46px;
  }
  .adminIcon {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .alertDiv {
    display: none;
  }
  .topBarPremiseDowndown {
    width: 400px;
    max-width: 1000px;
    margin-left: 46px;
  }
  .adminIcon {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 1025px) {
  .topBarPremiseDowndown {
    width: 300px;
    max-width: 1000px;
    /* margin-left: 46px; */
    margin-right: 10px;
  }
  .alertDiv {
    font-size: 15px;
    margin-left: 0px !important;
  }
  .close-sidebar-btn {
    display: none;
  }
}

@media (min-width: 1245px) {
  .close-sidebar-btn {
    display: none;
  }
}
