@media (max-width: 600px) {
  .userFirstName {
    margin-bottom: 20px !important;
  }
  .userPhoneNo {
    width: 245px;
  }
}

.bar-existuser-0 {
  margin-left: 172px !important;
  margin-right: 50px !important;
}

.bar-existuser-1 {
  margin-right: -10px !important;
}

@media (min-width: 320px) and (max-width: 375px) {
  .bar-existuser-0 {
    margin-left: 105px !important;
    margin-right: 40px !important;
  }
  .bar-existuser-1 {
    margin-right: -74px !important;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  .bar-existuser-0 {
    margin-left: 105px !important;
    margin-right: 40px !important;
  }
  .bar-existuser-1 {
    margin-right: -74px !important;
  }
}

@media (min-width: 425px) and (max-width: 600px) {
  .bar-existuser-0 {
    margin-left: 105px !important;
    margin-right: 40px !important;
  }
  .bar-existuser-1 {
    margin-right: -74px !important;
  }
}

@media (min-width: 601px) and (max-width: 680px) {
  .bar-existuser-0 {
    margin-left: 96px !important;
    margin-right: 50px !important;
  }
  .bar-existuser-1 {
    margin-right: -90px !important;
  }
}

@media (min-width: 681px) and (max-width: 799px) {
  .bar-existuser-0 {
    margin-left: 125px !important;
    margin-right: 50px !important;
  }
  .bar-existuser-1 {
    margin-right: -60px !important;
  }
}

@media (min-width: 901px) and (max-width: 1024px) {
  .bar-existuser-0 {
    margin-left: 160px !important;
    margin-right: 60px !important;
  }
  .bar-existuser-1 {
    margin-right: -23px !important;
  }
  .existUserNoForm {
    padding: 0px 100px 0px 100px;
  }
  .nextBtnDiv {
    padding: 0px 100px 0px 100px;
  }
}

@media (min-width: 800px) and (max-width: 900px) {
  .bar-existuser-0 {
    margin-left: 127px !important;
    margin-right: 40px !important;
  }
  .bar-existuser-1 {
    margin-right: -53px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .existUserNoForm {
    padding: 0px 10px 0px 10px;
  }
  .nextBtnDiv {
    padding: 0px 10px 0px 10px;
  }
}

@media (min-width: 1270px) and (max-width: 1440px) {
  .bar-existuser-0 {
    margin-left: 155px !important;
    margin-right: 48px !important;
  }
  .bar-existuser-1 {
    margin-right: -25px !important;
  }
  .existUserNoForm {
    padding: 0px 105px 0px 105px;
  }
  .nextBtnDiv {
    padding: 0px 105px 0px 105px;
  }
}

@media (min-width: 1441px) and (max-width: 1599px) {
  .bar-existuser-0 {
    margin-left: 177px !important;
    margin-right: 60px !important;
  }
  .bar-existuser-1 {
    margin-right: 0px !important;
  }
  .existUserNoForm {
    padding: 0px 150px 0px 150px;
  }
  .nextBtnDiv {
    padding: 0px 150px 0px 150px;
  }
}

@media (min-width: 1600px) and (max-width: 1700px) {
  .bar-existuser-0 {
    margin-left: 200px !important;
    margin-right: 50px !important;
  }
  .bar-existuser-1 {
    margin-right: 20px !important;
  }
  .existUserNoForm {
    padding: 0px 150px 0px 150px;
  }
  .nextBtnDiv {
    padding: 0px 150px 0px 150px;
  }
}

@media (min-width: 1701px) and (max-width: 1800px) {
  .bar-existuser-0 {
    margin-left: 220px !important;
    margin-right: 70px !important;
  }
  .bar-existuser-1 {
    margin-right: 40px !important;
  }
  .existUserNoForm {
    padding: 0px 250px 0px 250px;
  }
  .nextBtnDiv {
    padding: 0px 250px 0px 250px;
  }
}

@media (min-width: 1801px) {
  .bar-existuser-0 {
    margin-left: 220px !important;
    margin-right: 70px !important;
  }
  .bar-existuser-1 {
    margin-right: 40px !important;
  }
  .existUserNoForm {
    padding: 0px 250px 0px 250px;
  }
  .nextBtnDiv {
    padding: 0px 250px 0px 250px;
  }
}
