/* src/components/DashboardLayout.css */
.dashboard-layout {
  display: flex;
}

.main-content.dashboardMain {
  margin-left: 210px; /* Sidebar width */
  flex-grow: 1; /* Allow the main content to fill remaining space */
  background: rgba(242, 242, 242, 1);
}

.content {
  padding: 3% 3% 3% 3%;
  /* margin-top: 60px;  */
  min-height: calc(100vh - 60px); /* Minimum height */
}

.contentDiv {
  margin-right: 20%;
}

.buttonValue {
  background: #2d64bc;
  /* background: linear-gradient(90.61deg, #33BB78 0%, #73ADE3 103.46%); */
  border: none !important;
  border-radius: 8px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  width: 40%;
}

img.addIcon {
  width: 12px;
  margin-bottom: 1%;
}

/* src/components/DashboardLayout.css */

.selectDiv {
  width: 50% !important;
  /* border: 1px solid #ccc !important; */
  /* border-radius: 8px !important;  */
  background-color: #fff !important; /* White background for the dropdown */
  transition: border-color 0.3s, box-shadow 0.3s !important; /* Smooth transition */
  appearance: none !important; /* Remove default dropdown arrow */
  position: relative !important; /* For the custom arrow */
  font-size: 12px !important;
  border: none !important;
}

.selectDiv:focus {
  /* border-color: #33BB78 !important; */
  box-shadow: 0 0 5px rgba(51, 187, 120, 0.5) !important;
}

.selectDiv option {
  background-color: #fff; /* White background for options */
  color: #333; /* Dark text color */
  font-size: 12px; /* Reduced font size for options */
  padding: 5px 10px; /* Optional padding for options */
}

/* Option hover state */
.selectDiv option:hover {
  background-color: #f0f0f0; /* Light gray on hover */
  color: #000; /* Darker text color */
}

/* Add a custom arrow */
.selectDiv::after {
  content: "";
  position: absolute !important;
  right: 15px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  border: solid transparent !important;
  border-width: 5px 5px 0 !important;
  border-top-color: #ccc !important; /* Color of the dropdown arrow */
  pointer-events: none !important; /* Prevent clicking on the arrow */
}

/* Hover state for dropdown */
.selectDiv:hover {
  border-color: #33bb78 !important; /* Change border color on hover */
}

/* Style the options dropdown */
.selectDiv option {
  background-color: #fff !important; /* White background for options */
  color: #333 !important; /* Dark text color */
}

/* Option hover state */
.selectDiv option:hover {
  background-color: #f0f0f0 !important; /* Light gray on hover */
  color: #000 !important; /* Darker text color */
}

.custom-tooltip {
  background-color: #333;
  color: white;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

.tooltip-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.tooltip-content {
  margin: 5px 0;
}

.tooltip-content strong {
  color: #4caf50;
}

.custom-tooltip p {
  margin: 0;
  padding: 0;
}

.StatisticsChart {
  padding: 10px;
  border: none;
}

.StatisticsDetailsCard.card-body {
  height: 100px !important;
}

.dateRangeStatistics {
  right: 50px !important;
}

.statisticsLabel {
  font-size: 14px !important;
}

.horizontalLine {
  margin-bottom: 6px !important;
}

.csvExportImage1 {
  width: 35px;
  cursor: pointer;
}

.pdfExportImage1 {
  width: 33px;
  margin-right: 6px;
  cursor: pointer;
}
