.time-label {
  margin-top: -0px;
  color: #4a90e2 !important;
}

.bluetoothSwitch {
  margin-left: 15px;
}

.react-switch-handle {
  height: 17px !important;
  width: 17px !important;
  top: 1.5px !important;
}

.bluetoothSwitchMainDiv {
  margin-top: -150px;
}

.premiseImageError {
  margin-bottom: 11px;
  text-align: center;
  margin-top: -3px;
}

.premise-name-error {
  margin-top: 3px !important;
  margin-bottom: -17px !important;
}

.bluetooth-div {
  margin-top: 33px !important;
  margin-bottom: 10px !important;
}
.timeDiv {
  margin-left: 50px !important;
}

.premiseAddMainDiv {
  padding: 30px 80px;
  border: none;
  background: #ffffff5c;
}

.premiseDetailsDiv,
.premiseDetailsDiv1 {
  padding-right: 60px;
  padding-left: 40px;
}

/* Styling for the professional title */
.header-title {
  font-size: 1.2rem; /* Reduced font size */
  letter-spacing: 1px; /* Slight letter spacing for a cleaner look */
  text-transform: uppercase; /* Uppercase text for a more formal look */
  font-family: "Helvetica Neue", sans-serif; /* A more professional font */
  font-weight: 600; /* Slightly reduced weight for a professional appearance */
  position: relative;
  display: inline-block; /* Makes the span an inline block for underline */
}

/* Styling for the underline always visible */
.header-title::after {
  content: ""; /* Empty content for the pseudo-element */
  position: absolute;
  bottom: -5px; /* Adjusts the underline position */
  left: 0;
  width: 100%;
  height: 3px; /* Underline thickness */
  background-color: #007bff; /* Bootstrap's primary blue color */
}

.formDiv {
  margin-top: 45px;
}

.styled-time-input {
  width: 130px;
  padding: 5px 7px;
  font-size: 15px;
  font-weight: bold;
  color: #1565c0;
  background-color: #f0f4f8;
  border: 1px solid #1565c0;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.styled-time-input:focus {
  outline: none;
  background-color: #e3f2fd; /* Highlight on focus */
  border-color: #0d47a1;
  box-shadow: 0 0 8px rgba(21, 101, 192, 0.5);
}

.styled-time-input:hover {
  border-color: #0d47a1; /* Slight change on hover */
}

.timePicker {
  margin-left: -130px;
}
.overall-time-div {
  width: 270px;
  margin-top: 55px;
}
.timeMainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.time-label {
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: 600;
  color: #6c757d;
  background-color: #fff;
  padding: 0 0.25rem;
  /* pointer-events: none; */
  transition: all 0.3s ease;
}

.timeCloseDiv {
  margin-left: 50px;
}

.openTimeMainDiv {
  margin-top: -10px;
  margin-left: 6px;
}
.timeOpenDiv {
  margin-left: 42px;
  margin-top: -32px;
  margin-bottom: 5px;
}

.file-label {
  display: block;
  cursor: pointer;
  /* border: 1px solid black; */
  padding: 5px;
  width: 170px;
  margin: 0px;
  border-radius: 10px;
}

.chooseFileBtn {
  padding: 0px 14px;
  font-size: 14px;
  border-radius: 3px;
}

.chooseFileDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-input {
  display: none;
}

.file-box {
  width: 100px;
  max-height: 150px;
  border: 2px dashed #ccc;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.text-image {
  display: inline-block;
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: -34px;
}

.PremiseImageMainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.premiseImageRoundDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

img.ImageUpload {
  width: 20px !important;
}

.file-input {
  display: none;
}

.file-box {
  position: relative;
  background: #e5e2e242;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  /* box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); */
}

.file-box:hover {
  transform: scale(1.04);
  /* box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); */
}

.ImageUpload {
  object-fit: contain;
  margin-bottom: 30px;
}

.upload-heading {
  font-size: 9px;
  margin-bottom: 0px;
  color: #333;
  margin: 0;
  font-weight: 700;
}

.image-instruction {
  font-size: 8px;
  color: #777;
  margin-top: 3px;
}

.image-preview {
  height: 100px !important;
  /* width: 150px !important; */
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid #fff;
}

.PremiseSubmitDiv {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 50px;
  margin-bottom: 20px;
}

.cancelBtn {
  margin-left: 20px;
}

.premiseForm {
  border: 1px solid #eae9e9;
  border-radius: 10px;
}

.bluetooth-label {
  color: #4a90e2 !important;
}

.PremiseDetailsMainDiv {
  margin-bottom: 0px !important;
}

.premiseFormTitle {
  margin-top: -20px;
  margin-bottom: 12px !important;
}

.premiseCreateForm {
  margin-bottom: -25px;
}

.premise-address-error {
  margin-top: -4px !important;
}

.premise-date-error {
  margin-top: 3px !important;
}
.premiseStepTwoForm,
.premiseStepThreeForm {
  margin-bottom: 12px !important;
}

.bar-timeoff-0 {
  margin-left: 183px !important;
  margin-right: 0px !important;
}

.bar-premise-1 {
  margin-right: 38px !important;
  margin-left: 46px !important;
}

.bar-premise-2 {
  margin-right: -105px !important;
}

.emitter-config::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.emitter-config::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px; /* Rounded edges for the track */
}

.emitter-config::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded edges for the thumb */
}

.emitter-config::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color when hovered */
}

.deleteItemDiv {
  margin-top: -9px;
  margin-bottom: -15px;
  margin-left: 29px;
}

.usersaveForm {
  margin-right: 12px;
}
.addNewRowDiv {
  margin-right: 0px;
}

.row.carriedDiv {
  margin-left: 32px;
  margin-right: 100px;
  margin-top: -15px;
}

.carriedMainDiv {
  margin-top: -20px;
}

.carriedText {
  font-size: 12px;
}

.ptag {
  margin-bottom: 0px !important;
  margin-top: 5px;
}

.viewOptionField:disabled {
  /* background-color: #e9ecef !important; */
  color: #6c757d !important; /* Dark gray text */
  cursor: not-allowed; /* Show disabled cursor */
  opacity: 1; /* Ensure consistent look across browsers */
}

.viewOptionField {
  /* background-color: #e9ecef !important; */
  color: #6c757d !important; /* Dark gray text */
  cursor: not-allowed  !important; /* Show disabled cursor */
  opacity: 1 !important; /* Ensure consistent look across browsers */
}

.otherOption {
  cursor: pointer;
}

@media (min-width: 425px) and (max-width: 600px) {
  .timeOffConfigDiv {
    width: 500px;
  }
  .MaintransferDiv{
    margin-right: 0px !important;
  }
  .transferText{
    margin-top: 35px !important;
  }
}
