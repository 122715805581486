
.change-password-card {
    box-shadow: 2px 4px 20px 8px rgba(0, 0, 0, 0.08);
    background: rgba(255, 255, 255, 1);
    width: 500px !important;
    height: 450px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}



/* Styling the input field */
.change-input {
    border: none;
    border-bottom: 1.5px solid #ccc;
    padding: 9px;
    border-radius: 2px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    background: rgba(216, 216, 216, 0.3);
    outline: none;
    color: rgba(0, 0, 0, 1);
    transition: border-color 0.3s ease-in-out;
}
  
.change-input:focus {
    outline: none;
    border-bottom-color: #00c9a7 !important; /* Change bottom border color on focus */
    box-shadow: 0 1px 0 0 rgba(0, 201, 167, 0.2); /* Optional: subtle shadow effect */
}



  .new-password-div{
    padding-bottom: 5%;
  }


img.closeImage {
    width: 16px;  
    border: none !important;
}

.closeBtn{
    border: none !important;
}

.change-password-input-div {
    position: relative;
}


.change-password {
    position: absolute;
    top: 8px;
    left: 290px;
}

.error-message{
    margin-bottom: 5px;
}

@media (min-width: 320px) and (max-width: 375px) {
    .forgot-password-form {
        margin: 0% 7%;
    }
    .change-password {
        left: 210px;
    }
}

@media (min-width: 375px) and (max-width: 425px) {
    .forgot-password-form {
        margin: 0% 10%;
    }
    .change-password {
        left: 235px;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .change-password {
        left: 240px;
    }
}